<script>

import { mapGetters } from 'vuex'
const formValues = () => ({
  regla_id: '',
  catalogo_id: '',
  clave: ''
})
const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'ReglasSatMainView',

  data () {
    return {
      regla_id: null,
      modal: false,
      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'descripcion', label: 'Descripción' }
      ],
      formValues: formValues(),
      response: response(),
      isSubmitting: false
    }
  },

  computed: {
    ...mapGetters('reglasSatModule', ['getResourceList', 'getReglasList']),
    selectedRegla () {
      return this.getResourceList.filter((item) => item.regla_id === this.regla_id)[0]
    }
  },

  methods: {
    async onChangeReglaId (id) {
      this.$store.dispatch('reglasSatModule/getReglas', { regla_id: id })
      this.formValues.regla_id = this.selectedRegla.regla_id
      this.formValues.catalogo_id = this.selectedRegla.catalogo_id
    },
    async onSubmitForm () {
      this.response = response()

      this.isSubmitting = true
      const { error, message, data } = await this.$store.dispatch('reglasSatModule/createResource', this.formValues)
      this.isSubmitting = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },
    onShowModal () {
      this.modal = true
    },
    onResetForm () {
      document.activeElement.blur()
      this.$formulate.reset('claveForm')
      this.formValues = formValues()
      this.response = response()
      this.modal = false
    }
  }
}
</script>

<template>
  <base-view title="Reglas de Catálogos del SAT" icon="check-circle">
    <div>
      <check-authorization :requiresAuthorizations="['indice reglas-sat']">
        <b-modal
          v-model="modal"
          centered
          size="lg"
          scrollable
          dialog-class="x-modal-md"
        >
          <template #modal-title>
            <span>Agregar clave</span>
          </template>
          <x-alert-with-errors
            :error="response.error"
            :title="response.message"
            :errors="response.errors"
          />
          <b-overlay :show="isSubmitting">
            <template #overlay>
              <div class="text-center">
                <loading message="Agregando clave, por favor espere..." />
              </div>
            </template>
            <FormulateForm
              ref="claveForm"
              name="claveForm"
              v-model="formValues"
              :errors="response.errors"
              @submit="onSubmitForm"
            >
              <b-card no-body>
                <b-card-header>
                  Escriba la clave que desee agregar
                </b-card-header>
                <div class="card-body">
                  <FormulateInput
                    name="clave"
                    label="Clave"
                    required
                    type="text"
                    validation="required"
                    validation-name="Clave"
                  />
                </div>
              </b-card>
            </FormulateForm>
          </b-overlay>
          <template #modal-footer>
            <!-- Enviar para crear o actualizar -->
            <div class="w-100">
              <x-form-footer-buttons
                :disabled="$refs.claveForm ? $refs.claveForm.hasErrors : false"
                :isEditing="false"
                :isCreating="true"
                @on-cancel="onResetForm"
                @on-create="onSubmitForm"
                @on-update="onSubmitForm"
              ></x-form-footer-buttons>
            </div>
          </template>
        </b-modal>
        <b-card no-body>
          <b-card-header>
            Claves válidas para las reglas de verificación del SAT
          </b-card-header>
          <b-card-body>
            <x-select
              class="mb-3"
              v-model="regla_id"
              dispatchPath="reglasSatModule/getResource"
              getterListPath="reglasSatModule/getResourceList"
              :formatListWith="['regla_id', 'regla_nombre']"
              defaultText="-- Selecciona una regla de validación --"
              noClearButton
              prependText="Regla SAT"
              @input="onChangeReglaId"
            >
            </x-select>
            <div class="mb-2 text-black-75"><b>Catálogo SAT:</b> {{ selectedRegla ? selectedRegla.catalogo_nombre : '' }}</div>
            <b-table
              id="reglasTable"
              :fields="fields"
              :items="getReglasList"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
            >
            </b-table>
            <b-button variant="primary" size="sm" @click="onShowModal">
              Agregar clave
            </b-button>
          </b-card-body>
        </b-card>
      </check-authorization>
    </div>
  </base-view>
</template>

<style scoped>

</style>
